<template>
  <div class="wrap">
    <div class="title">
      {{ dataInfo.resourceName }}
      <span class="status" :class="`audit-status-${dataInfo.auditStatus}`">
        {{ auditStatusMap[dataInfo.auditStatus] }}
      </span>
    </div>
    <div class="user">
      <a-avatar class="avatar" :size="30" :src="dataInfo.initMemberPortrait">
        <template #icon><UserOutlined /></template>
      </a-avatar>
      <div class="name">
        {{ dataInfo.initMemberName }}
      </div>
      <div class="depart">
        {{ dataInfo.initMemberDepartmentName }}
      </div>
    </div>
  </div>
  <div class="wrap">
    <!-- 名称 -->
    <div class="task-name">
      {{ $t("CM_LB_Name") }}: {{ dataInfo.resourceName }}
    </div>
    <div class="task-name" v-if="dataInfo.limitStartTime || dataInfo.limitEndTime">
      {{ $t('subject.learning_time') }}:
      <span v-if="dataInfo.limitStartTime">
        {{ dateFormat(dataInfo.limitStartTime, 'YYYY-MM-DD') }} 00:00
      </span>
      <span v-else>{{ $t('Unrestricted') }}</span>
      <span v-if="dataInfo.limitEndTime">
        ~
        {{ dateFormat(dataInfo.limitEndTime, 'YYYY-MM-DD') }} 23:59
      </span>
      <span v-else>{{ $t('Unrestricted') }}</span>
      <template v-if="dataInfo.studyCondition">
        (
        <span v-if="[1, 3].includes(dataInfo.studyCondition)">
          {{ $t('YouCanLearnBeforeYouStart') }}
        </span> 
        <span v-if="dataInfo.studyCondition === 3">、</span>
        <span v-if="[2, 3].includes(dataInfo.studyCondition)">
          {{ $t('YouCanLearnAtTheEnd') }}
        </span> 
        )
      </template>
    </div>
    <div class="action">
      <!-- 查看详情 -->
      <a-button type="link" @click="$refs.detailRef.show(dataInfo)">{{
        $t("view_detail")
      }}</a-button>
      <!-- 设置课程学习时间范围 -->
      <a-button type="link" @click="openPeriod(false)">{{
        $t("SetStudyPeriods")
      }}</a-button>
    </div>
  </div>
  <!-- 详情 -->
  <detail ref="detailRef" />
  <!-- 设置学习期限 -->
  <a-modal
    v-model:visible="visible"
    :title="`${dataInfo.initMemberName}--${$t('SetStudyPeriods')}`"
    :confirmLoading="loading"
    :maskClosable="false"
    @cancel="handleCancel"
    @ok="handleOk"
    width="550px"
    :bodyStyle="{ padding: '12px 24px' }"
  >
    <a-form
      :model="formData"
      ref="formRef"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item
        :label="$t('PeriodOfStudyRise')"
        name="studyLimitStartTime"
        :rules="{
          validator: validatorStart,
          trigger: 'change',
        }"
        class="mb10"
      >
        <a-date-picker
          class="w-full"
          allowClear
          v-model:value="formData.studyLimitStartTime"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :disabled-date="current => disabledDate(current, null, formData.studyLimitEndTime)"
          :placeholder="$t('CM_Select') + $t('PeriodOfStudyRise')"
        >
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-date-picker>
      </a-form-item>
      <a-form-item
        :label="$t('PeriodOfStudyStop')"
        name="studyLimitEndTime"
        :rules="{
          validator: validatorEnd,
          trigger: 'change',
        }"
        class="mb10"
      >
        <a-date-picker
          class="w-full"
          allowClear
          v-model:value="formData.studyLimitEndTime"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          :disabled-date="current => disabledDate(current, formData.studyLimitStartTime, null)"
          :placeholder="$t('CM_Select') + $t('PeriodOfStudyStop')"
        >
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-date-picker>
      </a-form-item>
      <a-form-item
        :label="$t('LearningSettings')"
        name="studyCondition"
        class="mb10"
      >
        <a-checkbox-group v-model:value="formData.studyCondition">
          <a-checkbox class="mr15" :value="1">{{ $t('LearnBeforeTheCourseStarts') }}</a-checkbox>
          <a-checkbox class="mr15" :value="2">{{ $t('LearnAfterTheCourse') }}</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
import { auditStatusMap } from "@/utils/business.js";
import { dateFormat } from "@/utils/tools.js";
import moment from 'moment';
import detail from "./detail.vue";
import { submitAudit } from "@/api/auditCenter";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const emit = defineEmits(["emit"]);
const props = defineProps({
  dataInfo: {
    type: Object,
    default: () => {},
  },
  setting: {
    type: Object,
    default: () => {},
  },
});

const visible = ref(false);
const formRef = ref(null);
const loading = ref(false);
const isSubmit = ref(false);
const isReset = ref(true);
const formData = reactive({
  studyLimitStartTime: '',
  studyLimitEndTime: '',
  studyCondition: [],
});

const auditParams = ref({});
function openPeriod(submit = false, data = {}) {
  isSubmit.value = submit;
  auditParams.value = data;
  if (!isReset.value) return visible.value = true;
  const { courseAuditData } = props.setting;
  formData.studyCondition = [];
  if (courseAuditData) {
    formData.studyLimitStartTime = courseAuditData.studyLimitStartTime ? dateFormat(courseAuditData.studyLimitStartTime) : '';
    formData.studyLimitEndTime = courseAuditData.studyLimitEndTime ? dateFormat(courseAuditData.studyLimitEndTime) : '';
    if (courseAuditData.studyCondition) {
      formData.studyCondition = courseAuditData.studyCondition === 3 ? [1, 2] : [courseAuditData.studyCondition];
    }
  } else {
    formData.studyLimitStartTime = '';
    formData.studyLimitEndTime = '';
  }
  isReset.value = false;
  visible.value = true;
};
function validatorStart(rules, value) {
  if (!value && formData.studyCondition.includes(1)) {
    return Promise.reject($t('CM_Select') + $t('PeriodOfStudyRise'));
  }
  return Promise.resolve();
}
function validatorEnd(rules, value) {
  if (!value && formData.studyCondition.includes(2)) {
    return Promise.reject($t('CM_Select') + $t('PeriodOfStudyStop'));
  }
  return Promise.resolve();
};
function disabledDate(current, startTime, endTime) {
  return (
    (startTime && current < moment(startTime).startOf('day')) ||
    (endTime && current > moment(endTime).endOf('day'))
  );
};
function handleCondition() {
  if (formData.studyCondition.length === 0) {
    return 0;
  }
  if (formData.studyCondition.length > 1) {
    return 3;
  }
  return formData.studyCondition[0];
};
function handleCancel() {
  visible.value = false;
  if (isSubmit.value) {
    const params = {
      courseAuditData: {
        ...formData,
        studyCondition: handleCondition(),
      },
      ...auditParams.value,
    };
    submit(params);
  }
  isSubmit.value = false;
}
async function handleOk() {
  await formRef.value.validate();
  const params = {
    courseAuditData: {
      ...formData,
      studyCondition: handleCondition(),
    },
    ...auditParams.value,
  };
  if (
    params.studyLimitStartTime &&
    params.studyLimitEndTime &&
    moment(params.studyLimitStartTime) > moment(params.studyLimitEndTime)
  ) {
    return proxy.$message.error($t('TheStartTimeCannotBeGreaterThanTheEndTime'));
  }
  if (!isSubmit.value) return handleCancel();
  submit(params)
}
async function submit(params) {
  const res = await submitAudit(params);
  isSubmit.value = false;
  if (res.ret === 0) {
    proxy.$message.success($t('CM_Success'));
    handleCancel();
    emit('on-success');
  } else {
    proxy.$message.error(res.msg);
  }
  isReset.value = true;
};
defineExpose({
  openPeriod,
});
</script>
<style lang="less" scoped>
.mr15 {
  margin-right: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.w-full {
  width: 100%;
}
</style>
